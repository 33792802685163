import { IoMdClose } from "react-icons/io";

export const Announcement = ({ setOpen }) => {
  return (
    <section id="announcement" className="bg-brand-gray relative">
      <div
        className="absolute right-[20px] top-[20px]"
        onClick={() => setOpen(false)}
      >
        <IoMdClose className="text-[24px] hover:text-brand-primary cursor-pointer transition-all" />
      </div>
      <div className="max-w-6xl mx-auto p-5 md:p-8">
        <div className="flex flex-col md:flex-row gap-5 lg:gap-8 items-center">
          <div className="w-full flex flex-col items-center text-center">
            <div className="flex flex-col">
              <h2 className="text-3xl mb-3 capitalize">Announcement</h2>
              <span className="block w-14 h-1 mx-auto bg-brand-primary"></span>
            </div>
            <p className="mt-3 md:mt-5 text-gray-500">August 2024</p>
            <p className="mt-3 max-w-[600px]">
              We are pleased to report Chart National Investment Management
              (CNIM) has completed an over $40 million first closing for its
              second fund Chart National II, L.P., with a majority of the
              interest coming from repeat Limited Partners. With a focus on
              buyouts of lower/mid-market National Security companies, CNIM will
              continue with rolling closes towards its target of $150mm.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
